.user-group-card {
  position: relative;
  padding: 22px 24px 24px;
  background-color: #fafafa;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 8px;

  &__name {
    width: 95%;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-align: left;
    color: var(--primary);
    transition: color .3s;

    &:hover {
      color: var(--info);
    }
  }

  &__info {
    width: 95%;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: #5a5a5a;
  }

  &__btn {
    box-sizing: content-box;
    position: absolute;
    padding: 8px;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;

    path {
      transition: fill .3s;
    }

    &:hover path {
      fill: var(--error);
    }
  }
}
