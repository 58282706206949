.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  &--small {
    .not-found__icon,
    svg {
      max-width: 150px;
      max-height: 150px;
    }

    .not-found__title {
      font-size: 20px;
      font-weight: bold;
      text-transform: none;
    }
  }

  &__icon,
  svg {
    margin-bottom: 15px;
    max-width: 500px;
    max-height: 500px;
    object-fit: contain;
  }

  &__icon path {
    fill: var(--primary);
  }

  &__title {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: var(--primary);
    text-transform: uppercase;

    &--color-primary {
      color: var(--info);
    }

    &--color-secondary {
      color: var(--error);
    }
  }
}
