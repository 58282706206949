$sizes: 100;
$mediumSizes: 300;
$bigSizes: 2000;
$unit: 1px;

// For margin & padding
@mixin generateIndentClass($prefix, $property) {
  @for $i from 1 through $mediumSizes {
    .#{$prefix}-#{$i} {
      #{$property}: $i * $unit;
    }
  }
}

$rules: [ [m, margin], [ml, margin-left], [mt, margin-top], [mb, margin-bottom], [mr, margin-right], [p, padding], [pl,
  padding-left], [pt, padding-top], [pb, padding-bottom], [pr, padding-right], ];

@each $item in $rules {
  @include generateIndentClass(nth($item, 1), nth($item, 2));
}

// For margin & padding - X/Y
@mixin indentXYClasses {
  @for $i from 1 through $mediumSizes {
    $value: $i * $unit;
    .mx-#{$i} {
      margin-left: $value;
      margin-right: $value;
    }
    .my-#{$i} {
      margin-top: $value;
      margin-bottom: $value;
    }
    .px-#{$i} {
      padding-left: $value;
      padding-right: $value;
    }
    .py-#{$i} {
      padding-top: $value;
      padding-bottom: $value;
    }
  }
}

@include indentXYClasses;

// For width
@mixin width {
  // width in percents
  @for $i from 1 through $sizes {
    .w-#{$i} {
      width: $i * 1%;
    }
  }

  // min/max-width in px
  @for $i from 0 through $bigSizes {
    $value: $i * $unit;
    .min-w-#{$i} {
      min-width: $value;
    }
    .max-w-#{$i} {
      max-width: $value;
    }
  }
}

@include width;

//Custom scrollbar
@mixin scrollbar($size, $thumb-color, $track-color, $thumb-radius: 0, $track-radius: 0) {
  // for Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: $thumb-radius;
    -webkit-border-radius: $thumb-radius;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
    border-radius: $track-radius;
    -webkit-border-radius: $track-radius;
  }
  // for Internet Explorer
  & {
    scrollbar-face-color: $thumb-color;
    scrollbar-track-color: $track-color;
  }
}
