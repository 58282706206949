.auth-main {
  overflow-y: auto;
  grid-column: 1 / -1;
  padding: 15px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--light);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 5px;

    &:window-inactive {
      background-color: var(--secondary);
    }
  }
}

.auth-box {
  margin: 80px auto;
  width: 480px;

  &__title {
    margin-bottom: 48px;
    text-align: center;
  }

  &__desc {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 48px 64px;
    border: 1px solid var(--secondary);
    border-radius: 16px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
  }

  &__checkbox {
    margin-top: 2px;
    margin-right: 8px;
    padding: 0;
    transition: .3s ease;

    &:hover, &:focus-within {
      opacity: .7;
    }
  }

  &__text {
    color: #5a5a5a;
  }

  &__resend {
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
  }

  &__resend-btn {
    margin-left: 12px;
    padding: 0;
    font-weight: bold;
    color: var(--primary);
    background-color: rgba(255, 255, 255, 0);
  }

  &__resend-countdown {
    margin-left: 12px;
    font-weight: bold;
    color: #90bcfc;
  }

  &__msg {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: var(--success);
    background-color: #def9e5;
    border-radius: 8px;

    &--alert {
      color: var(--error);
      background-color: #fcebeb;
    }

    svg {
      min-width: 16px;
    }
  }

  &__btm-link {
    display: block;
    margin: 48px auto auto;
    width: max-content;
  }
}

.auth-tabs {
  display: flex;
  border-bottom: 1px solid var(--secondary);

  &__btn {
    flex: 1;
    padding: 10px 18px;
    font-weight: 300;
    text-align: center;
    color: #8d8d8d;
    background-color: transparent;
    transition: color ease 0.3s, font-weight ease .3s;

    &:hover {
      font-weight: 500;
      color: var(--dark);
    }

    &--active {
      position: relative;
      font-weight: 500;
      color: var(--dark);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        border-bottom: 1px solid #0454c5;
      }
    }
  }
}
