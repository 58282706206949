.users-dialog {
  &__name {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
    letter-spacing: -0.25px;
    color: #8d8d8d;
  }

  &__radio-group {
    flex-direction: row;
    row-gap: 12px;
  }

  &__label {
    gap: 8px;
    margin-left: 0;
    margin-right: 0;
    transition: color .3s;

    &:hover {
      color: var(--primary);
    }

    .MuiTypography-body1 {
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  &__btn {
    display: block;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
  }

  small {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5a5a5a;
  }
}
