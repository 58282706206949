.fieldset {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-width: 0 0 2px 0;
  border-color: rgba(219, 219, 219, 0.5);

  &:disabled {
    opacity: .5;
    user-select: none;
    pointer-events: none;
  }

  &__label {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--gray);
  }
}
