@import 'src/assets/mixins';

.select-component-wrap {
  position: relative;
  display: inline-flex;
  min-width: 172px;

  &__label {
    position: absolute;
    top: 9px;
    left: 16px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #8d8d8d;
  }
}

.select-component {
  width: 100%;

  .select {
    &__control {
      align-items: flex-end;
      width: 100%;
      height: 56px;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary);
      background-color: var(--white);
      border: 1px solid var(--secondary);
      border-radius: 10px;
      cursor: pointer;

      &--is-focused,
      &:hover {
        border-color: var(--dark);
        box-shadow: none;
      }

      &--is-disabled {
        background-color: var(--light);
        border-color: var(--light);
      }

      &--menu-is-open {
      }
    }

    &__value-container {
      padding: 10px 16px;
      cursor: pointer;
    }

    &__placeholder {
      margin-left: 0;
      margin-right: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #8d8d8d;
    }

    &__single-value {
      margin-left: 0;
      margin-right: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }

    &__indicator {
      padding: 14px;
      cursor: pointer;
    }

    &__indicator-separator {
      display: none;
    }

    // Menu
    &__menu {
      z-index: 2;
      margin-top: 4px;
      background-color: var(--light);
      border: 1px solid #dbdbdb;
      border-radius: 8px;
      box-shadow: none;
    }

    &__menu-list {
      padding-top: 7px;
      padding-bottom: 7px;
      @include scrollbar(5px, var(--primary), var(--light), 5px, 2px);
    }

    &__option {
      position: relative;
      padding: 7px 15px;
      color: #5a5a5a;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &--is-focused {
        font-weight: 500;
        color: var(--dark);
        background: none;
      }

      &--is-selected {
        font-weight: 500;
        color: var(--dark);
        background: none;

        &:after {
          content: none;
        }
      }
    }
  }

  .css-b8ldur-Input {
    margin: 0;
  }

  .select-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transition: 0.3s;
  }

  .indicator-active {
    transform: scale(-1);
  }
}

// Searchable select
.select-component--searchable {
  .select {
    &__control,
    &__value-container {
      cursor: text;
    }
  }
}

// With search icon
.select-component--search-icon {
  .select {
    &__value-container {
      padding-left: 55px;

      &:before {
        content: url('./icons/search.svg');
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }
    }

    &__dropdown-indicator {
      display: none;
    }
  }
}
