.add-device-group {
  &__section {
    padding: 32px 32px 48px;
  }

  &__row {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  }

  &__add-btn {
    margin-top: 16px;
  }
}
