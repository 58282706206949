.users-table {
  &__user {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  &__name {
    font-weight: 700;
  }

  &__devices {
    position: relative;
    font-weight: bold;
    color: var(--primary);
    cursor: pointer;
  }

  &__no-data {
    color: var(--dark);
  }

  &__not-spec {
    color: var(--gray);
    opacity: .5;
  }

  .truncate-ellipsis {
    display: inline-block;
  }
}
