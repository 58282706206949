.owners-admins {
  &__section {
    padding: 32px 32px 48px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
  }

  &__pagination {
    margin-top: 0;
    padding-top: 32px;
    border-top: 1px solid rgba(219, 219, 219, 0.5);
  }
}
