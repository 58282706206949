.avatar-component {
  flex-shrink: 0;
  max-width: 144px;
  max-height: 144px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 800;
  color: var(--primary);
  background-color: var(--primary-light);
  border-radius: 50%;

  &__inner {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    border-radius: 50%;
  }
}
