.company-dialog {
  &__btn {
    display: block;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
  }

  &__group {
    padding: 12px 16px 16px;
    background-color: #f3f3f3;
    border-radius: 12px;
    word-wrap: break-word;

    span {
      display: block;
      margin-bottom: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #8D8D8D;
    }

    b {
      display: block;
      line-height: 20px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 14px;
    font-size: 12px;
    line-height: 16px;
    color: #3259a1;
    background-color: var(--info-light);
    border-radius: 8px;

    svg {
      flex-shrink: 0;
    }
  }
}
