.remote-link-box {
  margin: auto;
  max-width: 480px;
  width: calc(100vw - 32px);
  padding: 16px;

  &__title {
    margin-bottom: 48px;
    text-align: center;
    overflow-wrap: break-word;
  }

  &__desc {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 48px 64px;
    border: 1px solid var(--secondary);
    border-radius: 16px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
  }

  &__checkbox {
    margin-top: 2px;
    margin-right: 8px;
    padding: 0;
    transition: 0.3s ease;

    &:hover,
    &:focus-within {
      opacity: 0.7;
    }
  }

  &__text {
    color: #5a5a5a;
  }

  &__msg {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: var(--success);
    background-color: #def9e5;
    border-radius: 8px;

    &--alert {
      color: var(--error);
      background-color: #fcebeb;
    }

    svg {
      min-width: 16px;
    }
  }

  &__btm-link {
    display: block;
    margin: 48px auto auto;
    width: max-content;
  }
}
