.tooltip-wrap {
  display: flex;
  position: relative;

  &__tooltip {
    position: absolute;
    padding: 12px 20px;
    max-width: 258px;
    width: max-content;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--dark);
    background-color: var(--light);
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: visibility .2s, opacity .2s;

    span {
      display: block;
      font-size: 12px;
      line-height: 16px;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &:before, &:after {
      content: '';
      position: absolute;
    }

    &:before {
      border: 6.5px solid transparent;
    }

    &:after {
      border: 5px solid transparent;
    }
  }

  &--up &__tooltip {
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    &:before, &:after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &:before {
      border-top-color: #dbdbdb;
    }

    &:after {
      border-top-color: var(--light);
    }
  }

  &--left &__tooltip {
    bottom: 50%;
    left: -12px;
    
    transform: translateX(-100%) translateY(50%);

    &:before, &:after {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }

    &:before {
      border-left-color: #dbdbdb;
    }

    &:after {
      border-left-color: var(--light);
    }
  }

  &--hover:hover &__tooltip {
    visibility: visible;
    opacity: 1;
  }

  &--shown &__tooltip {
    visibility: visible;
    opacity: 1;
  }
}

