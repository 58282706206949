.owners-admins-table {
  &__user {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  &__role {
    font-weight: 500;
    color: var(--success);

    &.admin {
      color: var(--error-light);
    }
  }

  &__remove-btn {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    margin-right: 8px;
    padding: 8px;

    path {
      transition: fill .3s;
    }

    &:hover path {
      fill: var(--error);
    }
  }

  &__no-data {
    color: var(--dark);
  }

  .truncate-ellipsis {
    display: inline-block;
  }
}
