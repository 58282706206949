.header {
  display: flex;
  grid-column: 1 / -1;
  grid-row: 1;
  padding: 16px 48px;
  min-height: 100px;
  border-bottom: 1px solid var(--secondary);

  &__wrap {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
  }

  &__logo-link, &__logo {
    max-width: 156px;
    max-height: 40px;
  }

  &__col {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__text {
    margin-right: 12px;
  }
}
