.manage-users-table {
  &.table {
    thead tr {
      border: 0;
    }

    tr {
      border-top: 1px solid rgba(219, 219, 219, 0.5);

      &:not(:last-child) {
        border-bottom: 0;
      }
    }
  }

  tr {
    td:last-child {
      justify-content: flex-end;
      padding-right: 16px;
    }
  }

  &__user {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  &__name-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__name {
    line-height: 20px;
    font-weight: 700;
    color: var(--primary);
  }

  &__role {
    text-transform: capitalize;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--gray);

    &--owner {
      color: var(--success);
    }

    &--admin {
      color: var(--error-light);
    }
  }

  &__access-cell {
    flex-wrap: wrap;
    align-content: center;
    gap: 4px 8px;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  &__access-label {
    padding: 7px 13px;
    font-size: 9px;
    line-height: 11px;
    font-weight: 800;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #8d8d8d;
    border: 1px solid #8d8d8d;
    border-radius: 6px;
  }

  &__remove-btn {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    padding: 8px;

    path {
      transition: fill .3s;
    }

    &:hover path {
      fill: var(--error);
    }
  }

  &__more-wrap {
    position: relative;
    margin-right: 8px;
  }

  &__more-btn,
  &__expand-btn {
    padding: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: background-color .3s;

    &:hover,
    &:focus-visible,
    &--active {
      background-color: #f1f3f7;
    }
  }

  &__more-menu {
    position: absolute;
    top: 40px;
    right: -4px;
    padding-top: 14px;
    padding-bottom: 14px;
    min-width: 229px;
    background-color: var(--light);
    border: 1px solid var(--secondary);
    border-radius: 8px;
    z-index: 2;
  }

  &__more-item {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 10px 24px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--dark);
    transition: color .3s;

    path {
      transition: fill .3s;
    }

    &:hover, &:focus-visible {
      color: var(--info);

      path {
        fill: var(--info);
      }
    }

    &--red {
      &:hover, &:focus-visible {
        color: var(--error-light);

        path {
          fill: var(--error-light);
        }
      }
    }
  }

  &__expand-btn {
    transition: transform .3s;

    &--active {
      transform: scaleY(-1);
    }
  }

  &__no-data {
    color: var(--dark);
  }

  .expand-row {
    position: relative;
    max-height: 0;
    border-top-style: hidden !important;
    visibility: hidden;
    opacity: 0;
    transition: max-height .3s, visibility .3s, opacity .3s;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: -1px;
      left: 0;
      width: 48px;
      height: 1px;
      background-color: var(--light);
    }

    &--active {
      max-height: 72px;
      border-top-style: dashed !important;
      visibility: visible;
      opacity: 1;
    }

    &__limit {
      display: block;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: -0.25px;
      color: #747c89;
    }

    &__btn {
      padding: 8px;
      width: 32px;
      height: 32px;

      path {
        transition: fill .3s;
      }

      &:hover, &:focus-visible {
        path {
          fill: var(--info);
        }
      }

      &--red {
        &:hover, &:focus-visible {
          path {
            fill: var(--error-light);
          }
        }
      }

      &--disabled {
        path {
          fill: #B1B1B1;
        }

        &:hover, &:focus-visible {
          path {
            fill: #B1B1B1;
          }
        }
      }
    }
  }

  .truncate-ellipsis {
    display: inline-block;
  }
}
