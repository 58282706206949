.autocomplete {
  position: relative;

  &--disabled {
    opacity: .7;
    pointer-events: none;
    user-select: none;
  }

  .autocomplete-field {
    position: relative;
    padding: 38px 16px 16px;
    width: 100%;
    min-height: 74px;
    text-align: left;
    background-color: var(--light);
    border: 1px solid #dadada;
    border-radius: 10px;
    cursor: text;

    &:hover {
      border-color: var(--dark);
    }

    &__label {
      position: absolute;
      top: 18px;
      left: auto;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #8d8d8d;
      transition: top .2s, font-size .2s, line-height .2s, letter-spacing .2s;

      &.shrink {
        top: 12px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
    }

    &__input {
      flex-grow: 1;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      background-color: var(--light);
      border: 0;
      outline: 0;
      caret-color: rgba(28, 31, 36, .5);

      &:focus::placeholder {
        color: #8d8d8d;
      }

      &::placeholder {
        color: transparent;
        opacity: 1;
        transition: color .3s;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }

      &::-ms-clear,
      &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }
    }
  }

  .autocomplete-chip {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    max-width: 246px;
    height: 56px;
    background-color: var(--info-light);
    border-radius: 4px;
    cursor: default;
    transition: background-color .3s;

    &--error {
      background-color: #fcebeb;

      .autocomplete-chip {
        &__avatar {
          background-color: var(--error);
        }

        &__icon {
          circle {
            fill: var(--error);
          }

          path {
            fill: #ffffff;
          }
        }

        &__title {
          color: var(--error);
        }

        &__remove path {
          fill: var(--error);
        }
      }
    }

    &--warning {
      background-color: var(--warning-light);

      .autocomplete-chip {
        &__avatar {
          background-color: var(--warning-dark);
        }

        &__icon {
          circle {
            fill: var(--warning-dark);
          }

          path {
            fill: #ffffff;
          }
        }

        &__title {
          color: var(--warning-dark);
        }

        &__remove path {
          fill: var(--warning-dark);
        }
      }
    }

    &__inner {
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    &__avatar {
      flex-shrink: 0;
      margin-right: 8px;
      font-size: 12px;
      color: #ffffff;
      background-color: var(--primary);
      transition: background-color .3s;
    }

    &__icon {
      flex-shrink: 0;
      margin-right: 8px;
      width: 32px;
      height: 32px;

      path {
        fill: var(--primary);
        transition: fill .3s;
      }

      circle {
        transition: fill .3s;
      }
    }

    &__text-wrap {
      overflow: hidden;
      margin-right: 4px;
      font-size: 12px;
      line-height: 16px;
    }

    &__title {
      font-weight: bold;
      color: #1c1f24;
      transition: color .3s;
    }

    &__subtitle {
      display: block;
      font-weight: 300;
      color: #5a5a5a;
    }

    &__title,
    &__subtitle {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }

    &__remove {
      box-sizing: content-box;
      margin-right: -8px;
      padding: 8px;
      width: 16px;
      height: 16px;
      z-index: 1;

      path {
        fill: #3259a1;
        transition: fill .3s;
      }
    }

    &__tooltip {
      position: absolute;
      inset: 0;
    }
  }

  .autocomplete-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 3;
    margin-top: 4px;
    padding: 16px;
    background-color: var(--light);
    border-radius: 8px;
    border: 1px solid var(--secondary);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 64px;
      background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
      border-radius: 0 0 8px 8px;
      pointer-events: none;
    }

    &__list {
      margin-left: -6px;
      width: calc(100% + 12px);
      max-height: 280px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--light);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--secondary);
        border-radius: 3px;
      }
    }
  }

  .autocomplete-item {
    display: flex;
    align-items: center;
    padding: 8px 6px;
    cursor: pointer;
    transition: background-color .3s;

    &:hover, &:focus-visible {
      background-color: var(--info-light);
    }

    &--selected {
      background-color: var(--info-light);
    }

    &--disabled {
      pointer-events: none;

      .autocomplete-item__title {
        color: #747c89;
      }

      .autocomplete-item__subtitle {
        opacity: .5;
      }
    }

    &--invite,
    &--error {
      position: relative;
      z-index: 1;
    }

    &--error {
      pointer-events: none;
    }

    &__avatar {
      margin-right: 8px;
      flex-shrink: 0;
    }

    &__title {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }

    &__subtitle {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
      color: #5a5a5a;
    }
  }

  .autocomplete-loader {
    position: absolute;
    top: 50%;
    right: auto;
    transform: translateY(-50%);
    display: inline-block;
    margin-left: 4px;
    width: 20px;
    height: 20px;

    div {
      display: inline-block;
      position: absolute;
      left: 2px;
      width: 4px;
      background: var(--info-light);
      animation: autocomplete-loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

      &:nth-child(1) {
        left: 2px;
        animation-delay: -0.24s;
      }

      &:nth-child(2) {
        left: 8px;
        animation-delay: -0.12s;
      }

      &:nth-child(3) {
        left: 14px;
      }
    }

    @keyframes autocomplete-loading {
      0% {
        top: 2px;
        height: 16px;
      }
      50%, 100% {
        top: 6px;
        height: 8px;
      }
    }
  }
}
