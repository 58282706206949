.map-with-autocomplete {
  &__map {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 334px;
    background-color: #f3f3f3;
    border: 1px solid #dbdbdb;
    border-radius: 8px;

    &.no-data {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #5a5a5a;
      border-color: #f3f3f3;
    }
  }

  .places-autocomplete {
    position: relative;

    &__dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 3;
      margin-top: 4px;
      padding: 8px 16px;
      background-color: var(--light);
      border-radius: 8px;
      border: 1px solid var(--secondary);
    }

    &__list {
      overflow: auto;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: -6px;
      width: calc(100% + 12px);
      max-height: 356px;
      list-style: none;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--light);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--secondary);
        border-radius: 3px;
      }
    }

    &__dropdown-btn {
      display: block;
      margin-left: 0;
      margin-right: 0;
      padding: 8px 6px;
      width: 100%;
      text-align: left;
      transition: background-color .3s;

      &:hover, &:focus-visible {
        background-color: var(--info-light);
      }
    }
  }
}
