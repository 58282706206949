.activity-page {
  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;

    .select__single-value,
    .select__option {
      text-transform: capitalize;
    }
  }

  &__table {
    tr:last-of-type {
      border-bottom: 1px solid rgba(219, 219, 219, 0.5);
    }

    td:not(:first-child) {
      font-weight: 500;
    }

    svg {
      flex-shrink: 0;
    }
  }

  &__user-wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  &__name-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__role {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 500;

    &.owner {
      color: var(--success);
    }

    &.admin {
      color: var(--error-light);
    }
  }

  &__device {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center;
  }

  &__number {
    font-size: 12px;
    line-height: 16px;
    color: var(--gray);
  }
}
