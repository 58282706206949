.switch-mui {
    margin: 16px;
    padding: 0;
    height: 28px;
    width: 48px;

    &__track {
        border-radius: 16px;
        background-color: #B1B1B1;
        opacity: 1;
    }
    
    &__thumb {
        box-shadow: none;
        width: 20px;
        height: 20px;
    }

    &__base {
        padding: 4px;
    }

    &__label {
        margin-left: 0;
        margin-right: 0;

        .MuiTypography-body1 {
            font-family: inherit;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.switch-mui--checked {
    &.switch-mui-color {
        &--primary {
            color: #ffffff;

            +.switch-mui__track {
                background-color: var(--primary);
                opacity: 1;
            }
        }

        &--secondary {
            color: var(--error);
        }
    }
}
  