.user-device-card {
  position: relative;
  padding: 20px 24px 20px;
  min-height: 106px;
  background-color: var(--light);
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 8px;

  &__name {
    display: block;
    //margin-bottom: 2px;
    margin-right: 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  &__info {
    display: block;
    margin-top: 2px;
    //margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: #5a5a5a;
  }

  &__access {
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #5a5a5a;

    .is-admin {
      color: var(--error-light);
    }
  }

  &__owner {
    //margin-bottom: 14px;
    margin-top: 14px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: #5a5a5a;

    span {
      font-weight: bold;
      color: var(--primary);
    }
  }

  &__users {
    display: flex;
  }

  &__remove-btn {
    box-sizing: content-box;
    position: absolute;
    padding: 8px;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;

    path {
      transition: fill .3s;
    }

    &:hover path {
      fill: var(--error);
    }
  }
}
