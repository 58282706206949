.devices {
  &__section {
    padding: 32px 16px 49px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .tabs__menu {
    margin-left: 16px;
    margin-right: 16px;
  }

  &__list {
    margin-left: 16px;
    margin-right: 16px;
  }
}
