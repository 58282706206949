.skeleton-mui {
  &--text {
  }

  &--rect {
  }

  &--circle {
    flex-shrink: 0;
  }

  &--pulse {
  }

  &--wave {
  }

  &--with-children {
  }

  &--fit-content {
  }

  &--height-auto {
  }
}
