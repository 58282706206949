.text-field-root {
  .input-label {
    font-family: inherit;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    font-weight: 500;
    color: #8d8d8d;
    transform: translate(16px, 18px) scale(1);

    &--focused,
    &--shrink {
      letter-spacing: 0.2px;
      transform: translate(17px, 9px) scale(0.75);
    }

    &--error {
      color: var(--error);
    }

    &--disabled {
      color: #dadada;
    }
  }

  .input-field {
    min-height: 56px;
    font-family: inherit;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    font-weight: 500;
    color: var(--dark);
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #dadada;
    border-radius: 10px;

    &:hover, &--focused {
      border-color: var(--dark);
      //border-width: 1px;

      .input-field__input {
        //margin-left: -1px;
      }

      .password-btn,
      .MuiSelect-icon {
        //margin-right: -1px;
      }
    }

    &--multiline {
      padding-left: 0;
      padding-right: 16px;

      .input-adorned-end {
        position: absolute;
        top: 20px;
        right: 12px;
      }

      .MuiInputBase-inputMultiline {
        padding-bottom: 0;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background-color: var(--light);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--secondary);
          border-radius: 3px;
        }
      }
    }

    &--error {
      border-color: var(--error);
      border-width: 1px;

      &:hover {
        .input-field__input {
          margin-left: 0;
        }

        .password-btn,
        .MuiSelect-icon {
          margin-right: 0;
        }
      }
    }

    &__input {
      box-sizing: border-box;
      padding-bottom: 12px;
      padding-left: 16px;
    }

    &--disabled {
      color: #b1b1b1;
      background-color: #f8f8f8;
      border-color: #e9e9e9;
      border-radius: 10px;

      &:hover {
        border-color: #e9e9e9;
        border-width: 1px;

        .input-field__input {
          margin-left: 0;
        }
      }
    }
  }

  .password-btn {
    box-sizing: content-box;
    padding: 6px;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0);
  }

  .error-icon {
    fill: var(--error);
    cursor: pointer;
    z-index: 1;
  }

  .input-helper-text {
    margin-top: 8px;
    margin-left: 0;
    margin-right: 0;
    font-family: inherit;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    color: var(--dark);
  }

  // Select
  .input-select {
    position: relative;
    height: 54px;
    background-color: rgba(255, 255, 255, 0);

    &__icon {
      top: calc(50% - 10px);
      right: 18px;
      min-width: 20px;

      &--error {
        right: 36px;
      }
    }
  }

  &--search {
    .input-field:before {
      content: url('../../assets/images/search.svg');
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
    }

    .input-label {
      transform: translate(50px, 18px) scale(1);

      &--focused,
      &--shrink {
        transform: translate(50px, 9px) scale(0.75);
      }
    }

    .input-field__input {
      padding-left: 50px;
    }
  }
}
