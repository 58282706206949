.copy-to-clipboard {
  position: relative;
  padding: 4px;
  width: 24px;
  height: 24px;

  path {
    transition: fill .3s;
  }

  &:hover path {
    fill: var(--info);
  }
}
