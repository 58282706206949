:root {
  --primary: #0553c5;
  --secondary: #dbdbdb;
  --error: #e12626;
  --warning: #ffae00;
  --warning-light: #faebd6;
  --warning-dark: #f57c00;
  --info: #3796f6;
  --success: #1b8a35;
  --light: #fafafa;
  --dark: #131313;

  --primary-light: #e0e8f4;
  --error-light: #d76069;
  --info-light: #e5ebf7;
  --gray: #5a5a5a;
  --gray-light: #747c89;

  --container: 1344px;
}
