.devices-dialog,
.add-device-manually-dialog,
.device-users-dialog {
  &__name {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
    letter-spacing: -0.25px;
    color: #8d8d8d;
  }

  &__btn {
    display: block;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
  }
}


.del-group-dialog,
.remove-user-access-dialog {
  &__info {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 14px;
    font-size: 12px;
    line-height: 16px;
    color: #3259a1;
    background-color: var(--info-light);
    border-radius: 8px;

    svg {
      flex-shrink: 0;
    }
  }

  small {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5a5a5a;
  }
}

.transfer-ownership-dialog {
  .dialog-mui {
    &__paper, &__content {
      overflow: unset;
    }
  }

  .user-option {
    display: flex;
    align-items: center;
    width: 100%;

    &__avatar {
      margin-right: 8px;
    }

    &__title {
      display: block;
    }

    &__subtitle {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
      color: #5a5a5a;
    }
  }
}
