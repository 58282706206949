.default-button {
  padding: 12px;
  min-width: 164px;
  height: 48px;
  font-family: inherit;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  text-transform: none;
  border-radius: 8px;
  box-shadow: none;

  &:hover:not(:disabled) {
    opacity: 1;
  }

  &--contained {
    color: #ffffff;
    background-color: var(--primary);

    &:hover {
      background-color: var(--primary);
    }

    &:disabled {
      color: #ffffff;
      background-color: #90bcfc;
    }

    &.gray-btn {
      color: #1c1f24;
      background-color: #e9e9e9;

      &:hover {
        background-color: #e9e9e9;
      }
    }
  }

  &--outlined {
    color: var(--primary);
    border: 1px solid var(--primary);

    &:hover {
      background-color: rgba(5, 83, 197, 0.2);
    }

    &:disabled {
      color: #90bcfc;
      border-color: #90bcfc;
    }

    &.loading {
      color: var(--gray);
      border: 1px solid var(--primary);
    }

    &.gray-btn {
      color: var(--secondary);
      border-color: var(--secondary);

      &:hover {
        background-color: rgba(219, 219, 219, 0.2);
      }
    }
  }

  &.MuiButton-sizeSmall {
    padding: 5px;
    min-width: 108px;
    height: 36px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }

  &.MuiButton-fullWidth {
    width: 100%;
  }

  &.invite-btn {
    margin-left: auto;

    &:hover {
      color: #ffffff;
    }

    .add-icon {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
  }
}
