.manage-users {
  &__section {
    padding: 32px 32px 22px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
}
