.invite-users {
  &__section {
    padding: 32px 32px 48px;
  }

  &__row {
    display: block;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid rgba(219, 219, 219, 0.5);
  }

  &__legend {
    margin-bottom: 16px;
    font-family: inherit;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--gray);

    &.Mui-focused {
      color: var(--gray);
    }
  }

  &__radio-group {
    flex-direction: row;
    gap: 16px;
  }

  &__label {
    gap: 8px;
    margin-left: 0;
    margin-right: 0;
    min-width: 182px;
    transition: color .3s;

    &:hover {
      color: var(--primary);
    }

    .MuiTypography-body1 {
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  &__link-tooltip &__label {
    min-width: auto;
  }

  &__send-btn {
    margin-top: 16px;
  }
}
