.dialog-mui {
  &__paper {
    min-width: 480px;
    max-width: 480px;
    color: inherit;
    border-radius: 16px;
    box-shadow: none;
  }

  &__content {
    position: relative;
    overflow: auto;
    padding: 48px 64px;
    max-height: 850px;
    background-color: var(--light);
    border-radius: 16px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--light);
      border-radius: 2px;
      -webkit-border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 5px;
      -webkit-border-radius: 5px;

      &:window-inactive {
        background-color: var(--secondary);
      }
    }

    h2 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      letter-spacing: -0.25px;
      color: #1c1f24;
    }

    .default-button {
      min-width: 128px;
    }

    .btn-group {
      display: flex;
      justify-content: center;
      gap: 16px;
      margin-top: 48px;
    }
  }

  &__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    width: 32px;
    height: 32px;

    path {
      transition: 0.3s ease;
    }

    &:hover {
      path {
        opacity: 1;
        fill: var(--error);
      }
    }
  }

  &__close-icon {
    margin: auto;
  }
}
