.profile-field {
  position: relative;
  display: grid;
  padding: 9px 56px 12px 16px;
  width: 100%;
  min-height: 58px;
  border: 1px solid #dadada;
  border-radius: 10px;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  &__label {
    margin-bottom: 1px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #8d8d8d;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  &__btn {
    padding: 20px;
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    place-items: center;

    path {
      transition: .3s;
    }

    &:hover path {
      fill: var(--info);
    }
  }
}
