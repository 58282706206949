.manage-company {
  &__section {
    padding: 32px 32px 48px;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
  }

  &__fields {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 720px;
  }

  .text-field-root {
    width: 100%;
  }
}
