.company-card {
  position: relative;
  padding: 20px 24px 24px;
  min-height: 108px;
  background-color: var(--light);
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 8px;

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 6px;
    margin-right: 24px;
  }

  &__current {
    flex-shrink: 0;
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  &__desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: var(--gray);
  }

  &__more-wrap {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__more-btn {
    padding: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    outline: none;
    transition: background-color .3s;

    path {
      transition: fill .3s;
    }

    &:hover,
    &:focus-visible,
    &--active {
      background-color: #f1f3f7;
    }

    &:focus-visible path {
      fill: var(--primary);
    }
  }

  &__more-menu {
    position: absolute;
    top: 40px;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    padding-bottom: 14px;
    width: max-content;
    max-width: 260px;
    background-color: var(--light);
    border: 1px solid var(--secondary);
    border-radius: 8px;
    z-index: 2;
  }

  &__more-item {
    display: inline-flex;
    align-items: center;
    text-align: left;
    gap: 8px;
    padding: 10px 24px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--dark);
    outline: none;
    transition: color .3s;

    svg {
      flex-shrink: 0;
    }

    path {
      transition: fill .3s;
    }

    &:hover, &:focus-visible {
      color: var(--info);

      path {
        fill: var(--info);
      }
    }
  }
}
