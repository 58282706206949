.profile-dropdown {
  .profile-btn {
    margin-left: 24px;
    display: flex;
    align-items: center;

    &:hover, &:focus-visible {
      .profile-btn {
        &__username {
          color: var(--primary);
        }

        &__arrow path {
          fill: var(--primary);
        }
      }
    }

    &__info {
      margin-right: 12px;
      max-width: 180px;
      text-align: right;
    }

    &__username {
      display: block;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      transition: color .3s;
    }

    &__company {
      display: block;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: var(--gray-light);
    }

    &__arrow {
      margin-left: 8px;
      transform: scaleY(1);
      transition: transform .3s;

      path {
        transition: fill .3s;
      }

      &--open {
        transform: scaleY(-1);
      }
    }
  }

  .profile-nav {
    position: absolute;
    top: 100%;
    right: -12px;
    z-index: 5;
    margin-top: 8px;
    padding: 8px 16px 4px;
    min-width: 192px;
    list-style: none;
    background-color: var(--light);
    border: 1px solid var(--secondary);
    border-radius: 8px;

    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      text-align: left;
      line-height: 20px;
      font-weight: 500;
      color: var(--gray);
      transition: color .3s;

      &:hover, &:focus-visible {
        color: var(--primary);
      }

      &--profile {
        margin-bottom: 6px;
        border-bottom: 1px dashed rgba(219, 219, 219, 0.5);
      }

      &--small {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        color: var(--primary);

        &:hover, &:focus-visible {
          color: var(--info);
        }

        &:last-of-type {
          padding-bottom: 16px;
        }
      }

      &--bordered {
        color: var(--dark);
        border-top: 1px solid rgba(219, 219, 219, 0.5);
        border-bottom: 1px solid rgba(219, 219, 219, 0.5);
      }

      &--text {
        pointer-events: none;
      }

      &--active {
        justify-content: space-between;
        color: var(--dark);
        pointer-events: none;
        user-select: none;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .profile-tooltip {
    position: absolute;
    top: 100%;
    right: -16px;
    z-index: 4;
    margin-top: 12px;
    padding: 24px;
    max-width: 192px;
    background-color: #0454c5;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;

    &:before {
      content: '';
      position: absolute;
      top: -4.5px;
      right: 58px;
      transform: rotate(45deg);
      width: 9px;
      height: 9px;
      background-color: #0454c5;
    }

    &--active {
      opacity: 1;
      visibility: visible;
    }

    &__text {
      line-height: 20px;
      font-weight: 500;
      color: #ffffff;
    }

    &__btn {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      padding: 8px;
      transition: opacity .3s;

      &:hover, &:focus-visible {
        opacity: .7;
      }
    }
  }
}
