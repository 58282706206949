.toast {
  position: fixed;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px;
  min-width: 544px;
  min-height: 44px;
  background-color: var(--info-light);
  border-radius: 8px;

  &__text {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    color: #3259a1;
  }

  &__btn {
    margin-left: auto;
    width: 16px;
    height: 16px;

    &:hover {
      svg path {
        fill: var(--error);
      }
    }

    svg path {
      transition: .3s;
    }
  }
}
