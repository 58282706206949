.date-time-picker-mui {
  .MuiFormLabel-root {
    font-family: inherit;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    transform: translate(16px, 18px) scale(1);

    & + .MuiInput-formControl {
      margin-top: 0;
    }

    &.Mui-focused {
      color: var(--dark);
    }
  }

  .MuiInputLabel-shrink {
    transform: translate(0, -6px) scale(0.75);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    letter-spacing: 0.2px;
    transform: translate(17px, 9px) scale(0.75);
  }

  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(13px, 2px) scale(0.75);
  }

  .MuiInputBase-root {
    padding-right: 0;
    max-width: 200px;
    height: 56px;
    font-family: inherit;
    font-size: 16px;
    line-height: inherit;
    font-weight: 500;
    letter-spacing: normal;
    color: var(--dark);
    border-radius: 10px;

    &.Mui-disabled {
      color: var(--gray);
    }
  }

  .MuiInputBase-input {
    align-self: flex-end;
    padding: 10px 0 10px 16px;
  }

  .MuiIconButton-root {
    box-sizing: content-box;
    margin-right: 8px;
    padding: 8px;
    width: 20px;
    height: 20px;
    color: var(--primary);
    background-image: url('./icons/calendar.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;

    svg {
      opacity: 0;
    }
  }

  // Outline input
  .MuiOutlinedInput-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--dark);
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: var(--dark);
      }
    }

    &.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(19, 19, 19, 0.3);
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--error);
      }
    }

    legend span {
      display: none;
    }
  }

  .MuiOutlinedInput-input {
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #dadada;
    //transition: border-color 0.3s;
  }

  // Standard input & Filled input
  .MuiInput-underline,
  .MuiFilledInput-underline {
    &:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid var(--info);
    }

    &:before {
      border-color: var(--gray);
    }

    &:after {
      border-bottom: 1px solid var(--info);
    }
  }

  // Filled input
  .MuiFilledInput-root {
    background-color: var(--light);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .MuiInputAdornment-positionEnd {
    position: absolute;
    right: 0;
  }

  .MuiFormHelperText-root.Mui-error {
    color: var(--error);
  }
}

// Calendar
.MuiPickersBasePicker-container {
  .MuiPickersToolbar-toolbar,
  .MuiTabs-root {
    background-color: var(--primary);
  }

  .MuiPickersBasePicker-pickerView {
    background-color: #ffffff;
  }

  .MuiButton-text,
  .MuiTypography-root {
    font-family: inherit;
  }

  .MuiPickersDay-day {
    color: var(--primary);
  }

  .MuiPickersDay-dayDisabled {
    color: rgba(19, 19, 19, 0.3);
  }

  .MuiPickersDay-daySelected {
    color: var(--light);
    background-color: var(--primary);
  }
}

.MuiPickersModal-dialogRoot {
  .MuiButton-textPrimary {
    color: var(--primary);
  }
}
