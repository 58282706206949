.devices-table-row {
  thead tr {
    border: 0;
  }

  tr {
    border-top: 1px solid rgba(219, 219, 219, 0.5);

    &:not(:last-child) {
      border-bottom: 0;
    }
  }

  tr {
    td:last-child {
      justify-content: flex-end;
      padding-right: 16px;
    }
  }

  &__device_name_wrap {
    display: flex;
    flex-direction: column;
  }

  &__device_name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 2px;
  }

  &__device_online {
    color: var(--primary);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  &__device_offline {
    color: var(--error);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  &__remote_access {
    margin-left: 0;
  }

  &__more-wrap {
    position: relative;
    margin-right: 8px;
  }

  &__more-btn {
    padding: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: background-color .3s;

    &:hover,
    &:focus-visible,
    &--active {
      background-color: #f1f3f7;
    }
  }

  &__more-menu {
    position: absolute;
    top: 40px;
    right: -4px;
    padding-top: 14px;
    padding-bottom: 14px;
    min-width: 229px;
    background-color: var(--light);
    border: 1px solid var(--secondary);
    border-radius: 8px;
    z-index: 2;
  }

  &__more-item {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 10px 24px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--dark);
    transition: color .3s;

    path {
      transition: fill .3s;
    }

    &:hover, &:focus-visible {
      color: var(--info);

      path {
        fill: var(--info);
      }
    }

    &--red {
      color: var(--error);

      path {
        fill: var(--error);
      }

      &:hover, &:focus-visible {
        color: var(--error-light);

        path {
          fill: var(--error-light);
        }
      }
    }
  }

  &__no-data {
    color: var(--dark);
  }

  .truncate-ellipsis {
    display: inline-block;
  }

  &__counter {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  &__counter-seconds {
    color: var(--primary);
  }
  
}
  