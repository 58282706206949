.sort-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: max-content;
  text-align: left;
  font-weight: 500;
  color: #5a5a5a;

  &:after {
    content: url('../../assets/images/sort.svg');
    margin-left: 4px;
  }

  &--up:after {
    content: url('../../assets/images/sort-up.svg');
  }

  &--down:after {
    content: url('../../assets/images/sort-down.svg');
  }
}
