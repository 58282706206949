.tabs {
  &--small {
    .tabs {
      &__menu {
        gap: 32px;
        margin-bottom: 32px;
      }

      &__btn {
        padding-bottom: 12px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal;
      }
    }
  }

  &__menu {
    display: flex;
    gap: 48px;
    margin-bottom: 48px;
    border-bottom: 2px solid var(--secondary);
  }

  &__btn {
    position: relative;
    padding-bottom: 24px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: #8d8d8d;
    transition: color 0.3s;

    &:after {
      content: '';
      width: 0;
      transition: 0.3s;
    }

    &--active,
    &:hover,
    &:focus-visible {
      color: #1c1f24;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: var(--primary);
      }

      .tabs__counter {
        color: #8d8d8d;
      }
    }
  }

  &__counter {
    margin-left: 12px;
    font-weight: 300;
    color: rgba(141, 141, 141, 0.5);
    transition: color 0.3s;
  }

  &__item {
  }
}
