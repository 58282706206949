.edit-user-group {
  &__name {
    font-weight: 300;
    color: #8d8d8d;
  }

  &__section {
    padding: 32px 32px 48px;
  }

  &__row {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  }

  &__types {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 40px;
  }

  &__label {
    gap: 8px;
    margin-left: 0;
    margin-right: 0;
    min-width: 182px;
    transition: color .3s;

    &:hover {
      color: var(--primary);
    }

    .MuiTypography-body1 {
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
    gap: 16px;
  }

  &__add-btn {
    margin-top: 16px;
  }

  .type-item {
    min-width: 245px;

    &__label {
      display: block;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #5a5a5a;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
}
