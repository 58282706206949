.edit-device {
  &__section {
    padding: 32px 32px 48px;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
  }

  &__row {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);

    &--flex {
      display: flex;
      align-items: center;
      list-style: none;
    }
  }

  &__info:not(:last-child) {
    margin-right: 160px;
  }

  &__info-label {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #8d8d8d;
  }

  &__info-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  &__bottom-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
  }

  &__remote_access-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  &__remote_access-text {
    font-size: 14px;
    line-height: 17.64px;
    font-weight: 500;
    color: #979797;
    margin-bottom: 10px;
  }
}

.nfc-tag {
  display: inline-flex;
  align-items: center;
  margin-left: 64px;

  &__name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  &__status {
    margin-left: 24px;
    margin-right: 8px;
    padding: 16px 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #5a5a5a;
    background-color: #f3f3f3;
    border-radius: 10px;

    &--assigned {
      color: var(--success);
      background-color: #def9e5;
    }
  }

  &__icon {
    display: inline-flex;
    padding: 8px;
    cursor: pointer;
  }
}
