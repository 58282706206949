.sidebar {
  padding-top: 112px;
  border-right: 1px solid var(--secondary);

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 48px;
    font-weight: 500;
    color: #5a5a5a;
    transition: background-color .3s;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      width: 3px;
      height: 100%;
      background-color: var(--primary);
      opacity: 0;
      transition: opacity .3s;
    }

    &:hover, &:focus, &.active {
      font-weight: 700;
      color: var(--primary);
      background-color: rgba(4, 84, 197, 0.1);

      svg g {
        fill: var(--primary);
      }

      &:after {
        opacity: 1;
      }
    }
  }
}
