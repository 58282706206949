.number-field {
  position: relative;
  display: inline-flex;

  &__label {
    position: absolute;
    top: 50%;
    right: 49px;
    transform: translateY(-50%);
    color: #8d8d8d;
    z-index: 1;
  }

  &__input {
    padding: 18px 95px 18px 16px;
    width: 168px;
    height: 56px;
    font-weight: 500;
    background-color: var(--light);
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
    transition: border-color .1s;

    &:hover, &:focus-visible {
      border-color: var(--dark);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  &__btn-group {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    display: grid;
    gap: 2px;
    z-index: 1;
  }

  &__btn {
    width: 22px;
    height: 22px;
    transition: opacity .3s;

    &:hover {
      opacity: .7;
    }
  }
}
