.searchable {
  position: relative;

  &__no-options {
    display: block;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
  }

  .searchable-btn {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    padding: 11px 56px 11px 16px;
    width: 100%;
    min-height: 56px;
    text-align: left;
    border: 1px solid #dadada;
    border-radius: 10px;
    white-space: nowrap;
    outline: 0;

    &:hover, &:focus-visible {
      border-color: var(--dark);
    }

    &:disabled {
      opacity: .7;
      pointer-events: none;
      user-select: none;
    }

    &__label {
      position: absolute;
      top: 50%;
      left: auto;
      transform: translateY(-50%);
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #8d8d8d;
      transition: top .3s, font-size .3s, translate .3s;

      &.shrink {
        top: 9px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        transform: translateY(0);
      }
    }

    &__values {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }

    &__arrow {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
      transition: .3s;

      &--open {
        transform: translateY(-50%) scaleY(-1);
      }
    }
  }

  .searchable-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 3;
    margin-top: 4px;
    padding: 16px;
    background-color: var(--light);
    border-radius: 8px;
    border: 1px solid var(--secondary);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 48px;
      background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
      border-radius: 0 0 8px 8px;
      pointer-events: none;
    }

    &__search {
      width: 100%;

      .input-label {
        font-size: 14px;
        transform: translate(50px, 14px) scale(1);

        &--shrink {
          transform: translate(50px, 9px) scale(0.75);
        }
      }

      .input-field {
        min-height: 48px;
      }
    }

    &__list {
      margin-top: 8px;
      margin-left: -6px;
      width: calc(100% + 12px);
      max-height: 378px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--light);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--secondary);
        border-radius: 3px;
      }
    }
  }

  .searchable-item {
    display: block;
    margin-left: 0;
    margin-right: 0;
    padding: 8px 6px;
    width: 100%;
    text-align: left;
    transition: background-color .3s;

    &:hover, &:focus-visible {
      background-color: var(--info-light);
    }

    &--selected {
      background-color: var(--info-light);
      pointer-events: none;
    }

    &__title {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }

    &__subtitle {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
      color: #5a5a5a;
    }
  }
}
