.cropper-dialog {
  .dialog-mui {
    &__paper {
      min-width: auto;
      max-width: none;
    }

    &__content {
      padding: 48px 32px 24px;
    }
  }

  &__box {
    width: 100%;
    height: 400px;
  }

  &__btn {
    display: block;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}
