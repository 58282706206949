.radio-mui {
  padding: 0;
  color: var(--primary);

  &--checked {
  }

  &--disabled {
  }

  &--padding {
    padding: 8px;
  }

  &--small svg {
    width: 12px;
    height: 12px;
  }

  &.radio-mui-color {
    &--primary {
      color: var(--primary);
    }

    &--secondary {
      color: var(--error);
    }
  }
}
