.companies {
  &__section {
    padding: 32px 32px 48px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    margin-bottom: 48px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
    gap: 16px;
  }
}
