.user-page {
  &__top {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
  }

  &__section {
    padding: 32px 32px 48px;
  }

  &__row {
    display: flex;
    gap: 96px;
  }

  &__indent {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid rgba(#dbdbdb, .5);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
    gap: 16px;
  }

  &__add-device-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //min-height: 206px;
    min-height: 114px;
    text-align: center;
  }

  .tabs {
    &__menu {
      margin-bottom: 32px;
      border-bottom: 0;
    }

    &__btn {
      padding-bottom: 0;

      &:after {
        content: none;
      }
    }
  }

  .user-info {
    &__label {
      display: block;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #8d8d8d;
    }

    &__text {
      display: flex;
      gap: 4px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &__copy {
      margin-top: -3px;
    }
  }
}
