.profile-settings {
  &__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 352px;
  }

  &__sso-text {
    color: var(--gray);
  }
}
