.edit-device-group {
  &__section {
    padding: 32px 32px 48px;
  }

  &__name {
    font-weight: 300;
    color: #8d8d8d;
  }

  &__row {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-auto-rows: 88px;
    gap: 16px;
  }

  &__add-btn {
    margin-top: 16px;
  }
}
