.profile-dialog {
  &__title {
    margin-bottom: 32px;
    text-align: center;
  }

  &__info {
    margin-bottom: 24px;
    text-align: center;
  }

  &__btn {
    display: block;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
  }
}
