.autocomplete-mui {
  position: relative;

  .MuiAutocomplete-inputRoot {
    padding: 27px 13px 10px;

    &[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
      padding: 0;
    }
  }

  .MuiAutocomplete-popper {
    width: 100% !important;
  }

  .MuiAutocomplete-paper {
    position: relative;
    font-family: inherit;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--dark);
    background-color: var(--light);
    border-radius: 8px;
    border: 1px solid var(--secondary);
    box-shadow: none;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 32px;
      background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
      border-radius: 0 0 8px 8px;
      pointer-events: none;
    }
  }

  .MuiAutocomplete-listbox {
    padding: 0;
    max-height: 280px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--light);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--secondary);
      border-radius: 3px;
    }
  }

  .MuiAutocomplete-option {
    padding: 14px;
    transition: background-color .3s;

    &[data-focus='true'],
    &[aria-selected='true'] {
      background-color: var(--info-light);
    }

    &[aria-disabled='true'] {
      pointer-events: none;
    }
  }
}
