.back-link {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;

  &:hover .back-link__icon path {
    fill: var(--info);
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &__icon path {
    transition: .3s;
  }
}
