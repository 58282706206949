.api-keys {
  &__section {
    padding: 32px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;
  }

  &__table {
  }

  &__del-btn {
    display: flex;
    margin-left: auto;
    margin-right: 16px;
    padding: 8px;

    path {
      transition: fill .3s;
    }

    @media (hover) {
      &:hover, &:focus-visible {
        path {
          fill: var(--error);
        }
      }
    }
  }
}