@import "reset";
@import "variables";
@import "mixins";
@import "fonts/PlusJakartaSans";

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark);
    background-color: var(--light);
    overflow: hidden;
}

.root {
    display: grid;
    grid-template-columns: 250px auto;
    grid-template-rows: 100px calc(100vh - 100px);
    min-height: 100vh;
}

h1,
.h1 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 48px;
}

a,
.link {
    font-weight: bold;
    text-decoration: none;
    color: var(--primary);
    transition: color 0.3s;

    &:hover {
        color: var(--info);
    }
}

button {
    color: var(--dark);
}

textarea {
    padding: 12px 16px;
    height: 122px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 14px;
    background-color: var(--light);
    border: 1px solid #dadada;
    border-radius: 10px;
    resize: none;
    transition: border-color .3s;

    &:hover, &:focus {
        border-color: var(--dark);
    }

    &::placeholder {
        color: #8d8d8d;
    }
}

.page-wrap {
    padding: 32px 48px;
    overflow-y: auto;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--light);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--primary);
        border-radius: 5px;

        &:window-inactive {
            background-color: var(--secondary);
        }
    }
}

.page-title {
    margin-bottom: 32px;

    &__inner {
        font-weight: 300;
        color: #8d8d8d;
    }
}

.section-wrap {
    background-color: var(--light);
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 16px;
}

.subtitle {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: #1c1f24;

    &__number {
        margin-left: 12px;
        font-weight: 300;
        color: #8d8d8d;
    }
}

.add-card-btn {
    padding: 28px 20px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: #5a5a5a;
    background-color: var(--light);
    border: 1px dashed rgba(151, 151, 151, 0.3);
    border-radius: 8px;

    b {
        color: var(--primary);
        transition: color .3s;
    }

    &:hover, &:focus-visible {
        color: #5a5a5a;

        b {
            color: var(--info);
        }
    }
}

.code-wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 304px;

    &__fields {
        display: flex;
        gap: 8px;
    }

    &__input {
        overflow: auto;
        height: 56px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        border: 1px solid #dadada;
        border-radius: 10px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }

        &:hover, &:focus {
            margin-left: -1px;
            margin-right: -1px;
            border: 2px solid var(--dark);
            outline: none;
        }
    }
}

// Table
.table {
    border-collapse: collapse;

    tr, th, td {
        display: flex;
        align-items: center;
    }

    thead tr,
    tr:not(:last-child) {
        border-bottom: 1px solid rgba(219, 219, 219, 0.5);
    }

    th {
        flex-wrap: wrap;
        vertical-align: middle;
        padding: 8px 16px;
        height: 49px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: var(--gray);
        text-align: left;
    }

    td {
        vertical-align: middle;
        padding: 8px 16px;
        height: 72px;
        line-height: 20px;
    }

    th, td {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.loading-text {
    padding: 32px;
    text-transform: none;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary);
}

// Helper classes
.m-auto {
    margin: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.t-center {
    text-align: center;
}

.good {
    color: var(--success);
}

.alert {
    color: var(--error);
}

.good-hover {
    transition: opacity ease 0.3s;

    &:hover {
        opacity: 0.7;
    }
}

.card-hover {
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
        margin-top: -3px;
        margin-bottom: 3px;
    }
}

.no-select {
    user-select: none;
}

.t-capitalize {
    text-transform: capitalize;
}

.t-lowercase {
    text-transform: lowercase;
}

.cursor-pointer {
    cursor: pointer;
}

.items-center {
    display: flex;
    align-items: center;
}

.bold {
    font-weight: 700;
}

.default-popover {
    background-color: transparent !important;
}

.with-scrollbar {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--light);
        border-radius: 2px;
        -webkit-border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--primary);
        border-radius: 5px;
        -webkit-border-radius: 5px;

        &:window-inactive {
            background-color: var(--secondary);
        }
    }
}

.full-width {
    width: 100%;
}

.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.truncate-ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// React Toastify
.Toastify {
    &__toast {
        padding-left: 16px;
        font-family: inherit;
        color: var(--dark);
        font-weight: 550;
        background-color: #ffffff;
        border-radius: 4px !important;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);

        &-container {
            min-width: 450px;
            z-index: 1500 !important;
        }
    }

    &__close-button {
        color: var(--dark);
    }

    &__progress-bar {
        &--error {
            background-color: var(--error);
        }

        &--success {
            background-color: var(--success) !important;
        }
    }
}
