.devices-table {
  &.table {
    thead tr {
      border: 0;
    }

    tr {
      border-top: 1px solid rgba(219, 219, 219, 0.5);

      &:not(:last-child) {
        border-bottom: 0;
      }
    }
  }

  tr {
    td:last-child {
      justify-content: flex-end;
      padding-right: 16px;
    }
  }

  &__device_name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 2px;
  }
}
