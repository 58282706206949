.profile-photo {
  display: flex;
  align-items: center;
  gap: 44px;
  margin-bottom: 32px;

  &__upload {
    span {
      display: block;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #5a5a5a;
    }

    label {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      color: var(--primary);
      transition: color .3s;
      cursor: pointer;

      &:hover,
      &:focus-within {
        color: var(--info);
      }
    }
  }

  &__remove-btn {
    margin-left: 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: var(--error);
    transition: color .3s;

    &:hover {
      color: var(--error-light);
    }

    &:disabled {
      color: #8d8d8d;
    }
  }
}
